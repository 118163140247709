<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            editor: ClassicEditor,
        }
    },
    components: {        
        ckeditor: CKEditor.component,
    },
    methods: {
        
    },
};
</script>

<template>
    <div class="row">
        <div class="form-group col-12">
            <ckeditor id="full_description" v-model="value.full_description" :editor="editor"></ckeditor>
            <div v-if="!v.promotion.full_description.required && submitted" class="invalid-feedback d-block">Ingresa la descripción de la promoción</div>
        </div>
    </div> 
</template>