<script>
import SelectAutocompleteField from "@/components/forms/select-autocomplete-field.vue";
import AddIndividualMediaButton from "@/components/buttons/add-individual-media-button.vue";
import Status from "@/helpers/general/status";
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            promotion_status: Status.getList('promotion_status'),
        }
    },
    components: {        
        SelectAutocompleteField,
        AddIndividualMediaButton,
    },
    methods: {
        
    },
};
</script>

<template>
    <div class="row">
        <div class="col-12">
            <label>Imagen de cabecera</label>
            <AddIndividualMediaButton v-model="value.header_media" :fullWidth="true" :uploadModalId="'add-individual-media-modal-header-media'"></AddIndividualMediaButton>
            <div v-if="!v.promotion.header_media.required && submitted" class="invalid-feedback d-block">Imagen de cabecera es requerida</div>
        </div>
        <div class="form-group col-12">
            <label for="title">Titulo</label>
            <input id="title" v-model="value.title" type="text" class="form-control" placeholder="Ingresa un nombre"
                :class="{ 'is-invalid': v.promotion.title.$error && submitted }" />
            <div v-if="!v.promotion.title.required && submitted" class="invalid-feedback">Titulo es requerido</div>
        </div>
        <div class="form-group col-12">
            <label>Categoría</label>
            <SelectAutocompleteField
                :url="`/product-categories`"
                :multiple="false"
                :placeholder="'Busca una categoría'"
                :label="'name'"
                v-model="value.product_category"
                :class="{ 'is-invalid': v.promotion.product_category.$error && submitted }"
            ></SelectAutocompleteField>
            <!-- <div v-if="!v.promotion.product_category_id.required && submitted" class="invalid-feedback">Creador es requerida</div> -->
        </div>
        <div class="form-group col-12" v-has-permission="{ permission: 'edit_user', action: 'remove' }">
            <label>Creador</label>
            <SelectAutocompleteField
                :url="`/users`"
                :multiple="false"
                :placeholder="'Busca un usuario'"
                :label="'name'"
                v-model="value.creator"
                :class="{ 'is-invalid': v.promotion.creator.$error && submitted }"
            ></SelectAutocompleteField>
            <div v-if="!v.promotion.creator.required && submitted" class="invalid-feedback">Creador es requerida</div>
        </div>
        <div class="form-group col-12" v-has-permission="{ permission: 'edit_user', action: 'remove' }">
            <label for="status">Estado</label>
            <select v-model="value.status" class="form-control"
                :class="{ 'is-invalid': v.promotion.status.$error && submitted }">
                <option v-for="option in promotion_status" v-bind:value="option.id" :key="option.id">
                    {{ option.label }}
                </option>
            </select>
        </div>
        <div class="form-group col-12">
            <label>Etiquetas</label>
            <SelectAutocompleteField
                :url="`/tags`"
                :multiple="true"
                :taggable="true"
                :placeholder="'Busca o crea una etiqueta'"
                :label="'name'"
                :field="'name'"
                v-model="value.tags"
            ></SelectAutocompleteField>
            <!-- <div v-if="!v.promotion.product_category_id.required && submitted" class="invalid-feedback">Creador es requerida</div> -->
        </div>
        <div class="col-12">
            <label>Imagen destacada</label>
            <AddIndividualMediaButton v-model="value.feature_media" :uploadModalId="'add-individual-media-modal-feature-media'"></AddIndividualMediaButton>
            <div v-if="!v.promotion.feature_media.required && submitted" class="invalid-feedback d-block">Imagen destacada es requerida</div>
        </div>                            
    </div> 
</template>