<script>
export default {
    props: {
        value: Array,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            center: { lat: 40, lng: -3 },
            placeChangedTriggered: false,
        }
    },
    components: {        
    },
    methods: {
        // centerMap(lat, lng){
        //     this.center = {lat, lng};
        // },
        setLatLng(position, index){
            this.$set(this.value, index, {
                ...this.value[index],
                lat: position?.lat,
                lng: position?.lng,
            });
            
            // this.centerMap(position?.lat, position?.lng);
        },
        setAddress(event, index){   
            setTimeout(()=>{
                if (!this.placeChangedTriggered) {       
                    this.$set(this.value, index, {
                        ...this.value[index],
                        address: event?.target?.value
                    });
                }
                this.placeChangedTriggered = false;
            }, 300)
        },
        updateMarkerPosition(event, index) {
            this.setLatLng(event.latLng.toJSON(), index);
        },
        handlePlaceChanged(place, index) {
            this.placeChangedTriggered = true;
            if (place.geometry) {

                this.$set(this.value, index, {
                    ...this.value[index],
                    address: place.formatted_address 
                });

                this.setLatLng(place.geometry.location.toJSON(), index);
            } else {
                alert('Dirección no encontrada. Por favor, selecciona una dirección válida.');
            }
        },
        updateMarkerPositionManual(index) {
            const location = this.value[index];
            const lat = parseFloat(location.lat);
            const lng = parseFloat(location.lng);

            if (!isNaN(lat) && !isNaN(lng)) {
                this.setLatLng({ lat, lng }, index);
            } else {
                console.warn("Latitud o longitud no válidas:", location.lat, location.lng);
            }
        },
        
    },
};
</script>

<template>
    <div class="row">
        <div class="col-4">
            <div class="row" v-for="(location, i) in value" :key="i" :set="vloc = v.promotion.promotion_locations.$each[i]">
                <div class="col-12">
                    <div class="form-group">
                        <label for="address">Dirección</label>
                        <GmapAutocomplete
                            class="form-control"
                            @place_changed="(event) => handlePlaceChanged(event, i)"
                            @change="(event) => setAddress(event, i)"
                            ref="autocomplete"
                            :value="location.address"
                            placeholder="Buscar dirección"
                        />
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="lat">Latitud</label>
                        <input id="lat" v-model="location.lat" type="number" class="form-control" placeholder="Ingresa un nombre"
                            :class="{ 'is-invalid': vloc.lat.$error && submitted }" @input="updateMarkerPositionManual(i)"/>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="lng">Logitud</label>
                        <input id="lng" v-model="location.lng" type="number" class="form-control" placeholder="Ingresa un nombre"
                            :class="{ 'is-invalid': vloc.lng.$error && submitted }" @input="updateMarkerPositionManual(i)"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-8">
            <gmap-map :center="{ lat: parseFloat(value[0]?.lat), lng: parseFloat(value[0]?.lng) }" :zoom="9" style="height: 400px">
            <gmap-marker
                v-for="(location, index) in value"
                v-show="location.lat && location.lng"
                :key="index"
                :position="{ lat: parseFloat(location.lat), lng: parseFloat(location.lng) }"
                :clickable="true"
                :draggable="true"
                :title="location?.address"
                @dragend="updateMarkerPosition($event, index)" 
            ></gmap-marker>
            </gmap-map>
            <!-- @click="center = { lat: parseFloat(location?.lat), lng: parseFloat(location?.lng) }" -->
        </div>
    </div> 
</template>